import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

export const Container = styleWithOrgDesign(styled.div`
    width: 100%;

    background-color: ${(props) => props.organizationDesign.primary_color}1A;

    color: ${(props) => props.organizationDesign.primary_color};

    display: flex;
    flex-direction: row;

    gap: 16px;
    padding: 10px 16px;

    border-radius: 8px;
`)

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    margin: 0;
    padding: 0;
`

export const Collapsable = styled.div`
    max-height: ${(props) => (props.isOpened ? "auto" : "0")};
    transition: max-height 0.2s ease;

    overflow: hidden;

    & > p {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
    }
`

export const ToggleButton = styled.button<{ isOpened: boolean }>`
    width: 100%;
    line-height: 150%;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    border: 0;
    background: transparent;
    color: inherit;

    margin: 0;
    margin-top: ${(props) => (props.isOpened ? "8px" : "0")};
    padding: 0;

    & > p {
        margin: 0;
        font-size: 12px;
    }
`

export const RotateIcon = styled.div`
    transform: ${(props) => (props.shouldRotate ? "rotate(180deg)" : "rotate(0deg)")};
    font-size: 10px;
    transition: transform 0.2s ease;
`
