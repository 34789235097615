// vendor modules
import axios from "axios"
import { List, Map, fromJS } from "immutable"
// internal modules
import { TASTYPIE_QUERY_LIMIT } from "shared/imports/sharedConstants"
import actionTypes from "shared/search/action-types"
import bulkActionsActionTypes from "shared/search/searchify/bulkactions/action-types"
import searchifyActionTypes from "shared/search/searchify/action-types"
import { updateSearchifyCount } from "shared/search/searchify/action-creators"
import { selectCurrentFilters } from "shared/search/selectors"
import {
    filterHasValue,
    getQdtDefaultSearchFilters,
    handleSearchError,
    stringifyFilterValue,
} from "shared/search/helperFunctions"

import { loadCustomFields } from "shared/customFields/action-creators"
import { getActionCenters } from "shared/grassroots/action-creators"

// for query checking
// eslint-disable-next-line import/named
import { showQueryIssue } from "app/static/frontend/imports/backenderror"

export const showChangedInListManuallyFilter = () => (dispatch) => {
    dispatch({ type: actionTypes.SET_SHOW_MANUALLY_CHANGED_IN_LIST_FILTER, payload: true })
}

export const hideChangedInListManuallyFilter = () => (dispatch) => {
    dispatch({ type: actionTypes.SET_SHOW_MANUALLY_CHANGED_IN_LIST_FILTER, payload: false })
}

export function loadSearchPreloadData() {
    return (dispatch) => {
        dispatch({
            type: actionTypes.LOAD_SEARCH_PRELOAD_DATA_START,
        })

        Promise.all([
            dispatch(loadDefaultSearchFilters()),
            dispatch(loadCustomFields(Userdata.organization_id)),
            dispatch(getActionCenters()),
        ])
            .then(() =>
                dispatch({
                    type: actionTypes.LOAD_SEARCH_PRELOAD_DATA_SUCCESS,
                }),
            )
            .catch((err) => {
                console.warn("Load preload data fail: ", err)
                dispatch({
                    type: actionTypes.LOAD_SEARCH_PRELOAD_DATA_FAIL,
                })
            })
    }
}

/**
 * Registers a filterable list view with the store, which creates a slice of the
 * store wherein filters are stored.
 *
 * @name registerFilter
 * @function
 * @param {string} uniqueFlvId - Unique identifier for the filterableListView.
 * uniqueFlvId is generated in search/index.js
 */
export function registerFilterableListView(uniqueFlvId) {
    return {
        type: actionTypes.REGISTER_FILTERABLE_LIST_VIEW,
        uniqueFlvId,
    }
}

export function registerFlvDataType(uniqueFlvId, quorumDataType, resourcePath) {
    return {
        type: actionTypes.REGISTER_FLV_DATA_TYPE,
        uniqueFlvId,
        quorumDataType,
        resourcePath,
    }
}

export function unregisterFilterableListView(uniqueFlvId) {
    return {
        type: actionTypes.UNREGISTER_FILTERABLE_LIST_VIEW,
        uniqueFlvId,
    }
}

export function clearAllFilterableListViews() {
    return { type: actionTypes.CLEAR_ALL_FILTERABLE_LIST_VIEWS }
}

export function storeLastLoadedUserUpdate(uniqueFlvId, resourceUpdatedTimestamp) {
    return {
        type: actionTypes.STORE_LAST_USER_UPDATE,
        uniqueFlvId,
        resourceUpdatedTimestamp,
    }
}

/**
 * Given a multifilter filter info object, generate all filter slices that are
 * associated with that multifilter. This means the subfilters and the parent filter,
 * with some tricks done in order to make sure initial values are properly set.
 *
 * Multifilters need a lot of love, because they're more complex.
 *
 * @param  {Object}    the filter info object of the multifilter. Should contain
 *                     all pertinent information (key, subfilters, etc).
 *
 * @return {[type]}    An object of format { uniqueFilterId: filterSlice },
 *                     where filterSlice is of format {
 *                         hasValue: Boolean,
 *                         uniqueFilterId: string,
 *                         uniqueFilterGroupId: string,
 *                         value: string,
 *                         serializationKey: string
 *                     }
 */
export const generateMultiFilterSlices = ({
    uniqueFilterId,
    uniqueFilterGroupId,
    serializationKey,
    subfilters,
    computeFilterKeyFromSubfilters,
    computeFilterValueFromSubfilters,
}) => {
    // first, generate the filter slices for the subfilters! This is relatively
    // straightforward, it's basically like a regular filter (with a few differences).
    // .flat() is necessary for the subsubfilters in
    // createTransactionAmountFilter/multiGenericFilterTransactions
    const subfilterSlices = subfilters.flat().reduce((acc, subfilter) => {
        const updateAccumulator = (filter) => {
            acc[filter.defaultProps.uniqueFilterId] = {
                filterKey: "",
                uniqueFilterGroupId,
                serializationKey: filter.defaultProps.filterComponent.defaultProps.serializationKey,
                value: filter.defaultProps.initialValue,
                hasValue: false,
            }
        }

        if (typeof subfilter === "object") {
            Object.values(subfilter)
                .flat()
                .forEach((subsubfilter) => {
                    updateAccumulator(subsubfilter)
                })
        } else {
            updateAccumulator(subfilter)
        }

        return acc
    }, {})

    // We must convert our slices into the format { [filterId]: [filterValue] } in order
    // for `computeFilterValueFromSubfilters` to work.
    const subfilterValues = fromJS(subfilterSlices).map((filter) => filter.get("value"))

    // this is the initialValue of the parent multifilter (aka the only one that matters)
    // in terms of actual filtering.
    const initialValue = computeFilterValueFromSubfilters(subfilterValues)

    const multiFilterSlice = {
        [uniqueFilterId]: {
            filterKey: computeFilterKeyFromSubfilters(subfilterValues),
            uniqueFilterGroupId,
            serializationKey,
            value: initialValue,
            hasValue: filterHasValue(initialValue),
        },
    }

    return { ...multiFilterSlice, ...subfilterSlices }
}

/**
 * Given an array of filter info objects, generate the store that is necessary
 * in order for these filters to work happily. This replaces the old system
 * of registering each filter with the store one at a time -- instead of 40+ redux
 * calls, we'll only make one to register all filters. After all the filter slices
 * are generated, we'll make a call to modify the store. Will also modify
 * protectedFilters and multiFilters, which are external to the filters slice.
 *
 * @param  {string} uniqueFlvId     the unique ID of the filterable list view
 * @param  {Array}  filterObjs      array of filter info objects. Should contain
 *                                  everything we need in order to properly register
 *                                  the filter with the store.
 * @return void
 */
export const configureFlvSliceWithFilters = (uniqueFlvId, filterObjs, quorumDataType) => {
    // get the regular filter part of the filters store
    const regularFilterSlices = filterObjs
        .filter((filterObj) => !filterObj.subfilters)
        .reduce((acc, filterObj) => {
            acc[filterObj.uniqueFilterId] = {
                filterKey: filterObj.filterKey,
                uniqueFilterGroupId: filterObj.uniqueFilterGroupId,
                serializationKey: filterObj.serializationKey,
            }
            return acc
        }, {})

    // modify the protected filters.
    const protectedFilters = filterObjs
        .filter((filterObj) => filterObj.isProtected)
        .map((filterObj) => filterObj.uniqueFilterId)

    // get the multifilter part of the filters store
    const multiFilterSlices = filterObjs
        .filter((filterObj) => filterObj.subfilters)
        .map((filterObj) => generateMultiFilterSlices(filterObj))
        .reduce((acc, filterSlices) => ({ ...acc, ...filterSlices }), {})

    // get the multiFilters part of the store (it's the multifilter specs,
    // which get stored in a separate part of the store because we don't want
    // the filters slice of the store to get bloated). This includes instructions
    // on what to do in the case a subfilter gets updated.
    const multiFilters = filterObjs
        .filter((filterObj) => Boolean(filterObj.subfilters))
        .reduce((acc, filterObj) => {
            const {
                uniqueFilterId,
                allFilterKeys,
                subfilters,
                computeFilterValueFromSubfilters,
                computeFilterKeyFromSubfilters,
                computeSubfilterValuesFromParent,
            } = filterObj

            acc[uniqueFilterId] = {
                allFilterKeys,
                subfilterIds: subfilters.flat().reduce((acc, subfilter) => {
                    if (typeof subfilter === "object") {
                        Object.values(subfilter)
                            .flat()
                            .forEach((subsubfilter) => {
                                acc.push(subsubfilter.defaultProps.uniqueFilterId)
                            })
                    } else {
                        acc.push(subfilter.defaultProps.uniqueFilterId)
                    }

                    return acc
                }, []),
                computeFilterValueFromSubfilters,
                computeFilterKeyFromSubfilters,
                computeSubfilterValuesFromParent,
            }

            return acc
        }, {})

    // Add filterKey used for global date range in Dashboard so that it can segue to search with proper filters
    const customDateField = DjangIO.app.search.types.QuorumDataTypeOrdering.by_data_type_default_date[quorumDataType]
    const dateRangeField = DjangIO.app.search.types.QuorumDataTypeOrdering.by_data_type_date_range[quorumDataType]
    const regularFilterKeys = filterObjs.map((filterObj) => filterObj.filterKey)
    const noDisplayFilterKeys = []
    if (quorumDataType === DjangIO.app.models.QuorumDataType.note.value) {
        noDisplayFilterKeys.push("id__in")
    }
    if (dateRangeField) {
        noDisplayFilterKeys.push(dateRangeField)
    }
    if (customDateField) {
        noDisplayFilterKeys.push(`${customDateField}__gt`)
        noDisplayFilterKeys.push(`${customDateField}__lt`)
    }
    const noDisplayFilters = noDisplayFilterKeys.reduce((acc, filterKey) => {
        if (filterKey && !regularFilterKeys.includes(filterKey)) {
            const uniqueFilterId = `noDisplay${
                DjangIO.app.models.QuorumDataType.by_value(quorumDataType).custom_fields_url_pattern
            }${filterKey.split("_").join("")}`
            acc[uniqueFilterId] = {
                filterKey,
                uniqueFilterGroupId: "noDisplayFilterGroup",
                serializationKey: "noDisplayFilter",
            }
        }
        return acc
    }, {})

    const payload = {
        filters: { ...regularFilterSlices, ...multiFilterSlices, ...noDisplayFilters },
        protectedFilters,
        multiFilters,
    }

    // reducer time! Look at that generic update reducer. :)
    return {
        type: actionTypes.UPDATE_FLV_SLICE,
        uniqueFlvId,
        payload,
    }
}

export function registerSerializationFunctions(filterObjs) {
    return (dispatch, getState) => {
        const currentSerializationKeys = getState().search.get("serializeFunctions").keySeq().toJS()

        const payload = filterObjs
            .filter((filterObj) => !currentSerializationKeys.includes(filterObj.serializationKey))
            .reduce(
                (acc, { serializationKey, serializeFunc, deserializeFunc }) => {
                    if (!acc.serializeFunctions[serializationKey]) {
                        acc.serializeFunctions[serializationKey] = serializeFunc
                        acc.deserializeFunctions[serializationKey] = deserializeFunc
                    }
                    return acc
                },
                { serializeFunctions: {}, deserializeFunctions: {} },
            )

        dispatch({
            type: actionTypes.SEARCH_STORE_MERGE_DEEP,
            payload,
        })
    }
}

/**
 * Updates relevant filter in the store.
 *
 * @name updateFilter
 * @function
 * @param {string} uniqueFilterId - Unique identifier for the filter.
 * @param {string} uniqueFlvId - Unique identifier for the filterable list view.
 * @param {string} value - Serialized filter value ready to be sent to the server.
 * @param {boolean} hasValue - Serialized filter value ready to be sent to the server.
 */
export function updateFilter(uniqueFilterId, uniqueFlvId, value, hasValue) {
    return (dispatch, getState) => {
        if (["articleDateListFilter", "articleDateStart"].includes(uniqueFilterId) && !hasValue) {
            // if the date filter for article is being removed, check if there is another date filter applied.
            // if no date filter exist after removal, we should show an error and do nothing
            const dateFilter = ["articleDateListFilter", "articleDateStart"].filter(
                (filter) => filter !== uniqueFilterId,
            )
            const existingDateValue = getState().search.getIn(
                ["filterableListViews", uniqueFlvId, "filters", dateFilter[0], "value"],
                null,
            )
            if (!existingDateValue) {
                if (
                    uniqueFlvId === "Searchify-0" &&
                    getState().search.getIn([
                        "filterableListViews",
                        uniqueFlvId,
                        "searchify",
                        "searchifyHeaderName",
                    ]) === "Tracking Dashboard"
                ) {
                    swal({
                        icon: "error",
                        title: "Oops!",
                        text:
                            "News article tracking boards are limited to the past year. Please apply the date filter before applying your selection. " +
                            "It is recommended to use todays date in the start date field.",
                        actions: { ok: "OK" },
                    })
                } else {
                    swal({
                        icon: "error",
                        title: "Oops!",
                        text: "News Article search requires a date filter. Please apply a new date filter before removing this filter.",
                        actions: { ok: "OK" },
                    })
                }
                return
            }
        }

        const multiFilterId = getState()
            .search.getIn(["filterableListViews", uniqueFlvId, "multiFilters"], Map())
            .findKey((v) => v.get("subfilterIds").includes(uniqueFilterId))

        dispatch({
            type: actionTypes.UPDATE_FILTER,
            uniqueFilterId,
            uniqueFlvId,
            value,
            hasValue,
        })

        dispatch({
            type: bulkActionsActionTypes.UPDATE_FILTER,
            uniqueFilterId,
            uniqueFlvId,
            value,
            hasValue,
        })

        // if the filter is involved in a subfilter in a multifilter, we should update the multifilter
        // as well!
        if (multiFilterId) {
            const multiFilterObj = getState().search.getIn([
                "filterableListViews",
                uniqueFlvId,
                "multiFilters",
                multiFilterId,
            ])

            const subfilterValues = getState()
                .search.getIn(["filterableListViews", uniqueFlvId, "filters"])
                .filter((v, key) => multiFilterObj.get("subfilterIds").includes(key))
                .reduce((acc, v, key) => acc.set(key, v.get("value")), Map())

            const newValue = multiFilterObj.get("computeFilterValueFromSubfilters")(subfilterValues)
            const newFilterKey = multiFilterObj.get("computeFilterKeyFromSubfilters")(subfilterValues)

            dispatch(updateFilter(multiFilterId, uniqueFlvId, newValue, !!newValue))
            dispatch(updateFilterKey(multiFilterId, uniqueFlvId, newFilterKey))
        }
    }
}

/**
 * Updates relevant filters in the store. Does not do multifilter checking.
 *
 * @name updateMultipleFilters
 * @function
 * @param {string} uniqueFlvId - Unique identifier for the filterable list view.
 * @param {array} filtersToChange - Array of filter objects with uniqueFilterId, value, and hasValue
 */
export function updateMultipleFilters(uniqueFlvId, filtersToChange) {
    return {
        type: actionTypes.UPDATE_MULTIPLE_FILTERS,
        uniqueFlvId,
        filtersToChange,
    }
}

/**
 * Updates relevant filter's filterKey. This should only be used in the case of multifilters.
 *
 * @name updateFilterKey
 * @function
 * @param {string} uniqueFilterId - Unique identifier for the filter.
 * @param {string} uniqueFlvId - Unique identifier for the filterable list view.
 * @param {string} filterKey - filterKey used for filtering.
 */
export function updateFilterKey(uniqueFilterId, uniqueFlvId, filterKey) {
    return {
        type: actionTypes.UPDATE_FILTER_KEY,
        uniqueFilterId,
        uniqueFlvId,
        filterKey,
    }
}

/**
 * Sets all filters that are currently active (i.e., displayed) to undefined.
 * Used in FilterSet "clear filters" button.
 *
 * @name resetFilters
 * @function
 */
export function resetFilters(uniqueFlvId, overrideProtected = false) {
    return {
        type: actionTypes.RESET_FILTERS,
        uniqueFlvId,
        overrideProtected,
    }
}

/**
 * Sets all default filters to undefined.
 * Used in FilterSet "clear default filters" button.
 *
 * @name resetDefaultFilters
 * @function
 */
export function resetDefaultFilters(uniqueFlvId) {
    return {
        type: actionTypes.RESET_DEFAULT_FILTERS,
        uniqueFlvId,
    }
}

/**
 * Registers the permanent filters to the FLV slice.
 *
 * @name registerPermanentFilters
 * @function
 * @param {string} uniqueFlvId - Unique identifier for the FLV.
 * @param {object} permanentFilters - filters in the form of { filterKey: value }
 */
export function registerPermanentFilters(uniqueFlvId, permanentFilters) {
    return {
        type: actionTypes.REGISTER_PERMANENT_FILTERS,
        uniqueFlvId,
        permanentFilters,
    }
}

export function convertFilterKeysToFilterIds(searchState, uniqueFlvId, filterKeyObject) {
    const filters = searchState.getIn(["filterableListViews", uniqueFlvId, "filters"], Map())
    const multiFilters = searchState.getIn(["filterableListViews", uniqueFlvId, "multiFilters"], Map())

    // because the filters are given to us in a { filterKey: value } state and we
    // want them to be in a { uniqueFilterId: value } state, we need to do some conversions.
    const convertedFilters = filterKeyObject.reduce((accFilters, value, filterKey) => {
        // find the filterId of the filter that contains our default search's filterKey.
        // first try the regular filters. If it's not there, try looking in the multifilters.
        const uniqueFilterId =
            filters.findKey((v) => v.get("filterKey") === filterKey) ||
            multiFilters.findKey((v) => v.get("allFilterKeys").includes(filterKey))

        if (!uniqueFilterId) {
            console.warn(
                `No filter matches default search {${filterKey}: ${value}}, please confirm that the filterKey exists.`,
            )
            return accFilters
        }

        const stringifiedValue = stringifyFilterValue(value)
        let converted = fromJS({ [uniqueFilterId]: { value: stringifiedValue, filterKey } })

        // if it's a multifilter, we want to also see deconstruct the multifilter
        // into individual subfilter parts and convert that as well.
        if (multiFilters.get(uniqueFilterId, Map()).size) {
            const multiFilterObj = multiFilters.get(uniqueFilterId)
            const subfilterValues = multiFilterObj.get("computeSubfilterValuesFromParent")(filterKey, stringifiedValue)

            const convertedSubfilters = Object.keys(subfilterValues).reduce(
                (subs, key) =>
                    subs.set(key, fromJS({ value: subfilterValues[key], filterKey: undefined, hasValue: false })),
                Map(),
            )

            converted = converted.merge(convertedSubfilters)
        }

        return accFilters.merge(converted)
    }, Map())
    return convertedFilters
}

/**
 * Applies initial filters to the store.
 *
 * @name applyInitialFilters
 * @function
 * @param {string} uniqueFlvId - Unique identifier for the FLV.
 * @param {object} filters - Object containing filters to apply to store.
 */
export function applyInitialFilters(uniqueFlvId, filters) {
    return (dispatch, getState) => {
        const processedFilters = convertFilterKeysToFilterIds(getState().search, uniqueFlvId, fromJS(filters))

        dispatch({
            type: actionTypes.APPLY_EXTERNAL_FILTERS,
            uniqueFlvId,
            filters: processedFilters,
        })
    }
}

/**
 * Registers the default filters to the filterableListView slice.
 *
 * @name storeProcessedDefaultFilters
 * @function
 * @param {string} uniqueFlvId - Unique identifier for the FLV.
 * @param {object} unprocessedDefaultFilters - the default filters as returned from the backend.
 */
export function storeProcessedDefaultFilters(uniqueFlvId, defaultSearchKey) {
    return (dispatch, getState) => {
        const unprocessedDefaultFilters = getState().search.getIn(["defaultSearchFilters", defaultSearchKey], Map())

        // because the filters are given to us in a { filterKey: value } state and we
        // want them to be in a { uniqueFilterId: value } state, we need to do some conversions.
        const defaultFilters = convertFilterKeysToFilterIds(getState().search, uniqueFlvId, unprocessedDefaultFilters)

        dispatch({
            type: actionTypes.STORE_PROCESSED_DEFAULT_FILTERS,
            uniqueFlvId,
            defaultFilters,
        })
    }
}

/**
 * Applies the default filters to the current filter store.
 *
 * @name applyDefaultSearchFilters
 * @function
 * @param {string} uniqueFlvId - Unique identifier for the FLV.
 */
export function applyDefaultSearchFilters(uniqueFlvId) {
    return {
        type: actionTypes.APPLY_DEFAULT_SEARCH_FILTERS,
        uniqueFlvId,
    }
}

/**
 * Toggles FilterGroup according to filter group ID and current state of FilterGroup.
 *
 * @name toggleFilterGroup
 * @function
 * @param {string} uniqueFilterGroupId - ID for the filter group that is open.
 */
export function toggleFilterGroup(uniqueFlvId, uniqueFilterGroupId) {
    return {
        type: actionTypes.TOGGLE_FILTER_GROUP,
        uniqueFilterGroupId,
        uniqueFlvId,
    }
}

export function openFilterGroup(uniqueFlvId, uniqueFilterGroupId) {
    return {
        type: actionTypes.OPEN_FILTER_GROUP,
        uniqueFilterGroupId,
        uniqueFlvId,
    }
}

export function closeAllFilterGroups(uniqueFlvId) {
    return {
        type: actionTypes.CLOSE_ALL_FILTER_GROUPS,
        uniqueFlvId,
    }
}

/**
 * Changes the active action button for visualizations and actions. Will
 * toggle off in the case the button is already active. Logic in reducer.
 *
 * @name setActiveActionButton
 * @function
 * @param {string} uniqueFlvId - ID for the FLV this is occurring in.
 * @param {string} uniqueActionButtonId - ID for the button that is active.
 */
export function setActiveActionButton(uniqueFlvId, uniqueActionButtonId) {
    return {
        type: actionTypes.SET_ACTIVE_ACTION_BUTTON,
        uniqueFlvId,
        uniqueActionButtonId,
    }
}

/**
 * Converts data in the store into a ready-to-execute XHR.
 *
 * @name buildRequest
 * @function
 * @param {object} baseQueryset - The initial queryset from which to apply filters.
 * @param {object} filters - All filters registered with the store.
 * @param {array} activeFilters - The currently active filters.
 * @returns {object} Object that can dispatch the XHR to retrieve data.
 */
export function buildRequest(baseQueryset, filters, ignoreFilters = []) {
    // reduce our filters to a dictionary by ensuring:
    // 1) it is in the list of our active filters
    // 2) it has a value
    const filterDict = filters.reduce((acc, valueObj, uniqueFilterId) => {
        if (valueObj.get("hasValue") && !ignoreFilters.includes(uniqueFilterId)) {
            // add the filterKey and the associated value to our dict
            acc[filters.getIn([uniqueFilterId, "filterKey"])] = filters.getIn([uniqueFilterId, "value"])
        }
        return acc
    }, {})

    // construct the request
    return baseQueryset.filter(filterDict)
}

export const buildRequestAndUrl = (state, resource, uniqueFlvId, ignoreFilters = []) => {
    const searchSlice = state.search

    const filters = searchSlice.getIn(["filterableListViews", uniqueFlvId, "filters"], Map())
    const permanentFilters = searchSlice.getIn(["filterableListViews", uniqueFlvId, "permanentFilters"], Map()).toJS()

    // we will want to add more configuration ability to
    // the getData method, for example, applying default filters, ordering, etc.
    const baseQueryset = resource.objects.filter(permanentFilters)

    // build our request from the store
    let request = buildRequest(baseQueryset, filters, ignoreFilters)

    const { isSearchifySubmittable, searchifyFilters } = searchSlice
        .getIn(["filterableListViews", uniqueFlvId, "searchify"], Map())
        .toJS()

    if (isSearchifySubmittable) {
        request = request.filter({
            searchify_filters: JSON.stringify(searchifyFilters),
            is_searchify_request: true,
        })
    }

    // get the request's url
    const url = request.buildUrl()
    return { url, request }
}

/**
 * Calls function to convert store data to XHR, executes the XHR, and then returns it.
 *
 * @name fetchSearchData
 * @function
 * @param {function} resource - The resource from which to get the data.
 * @returns {object} Returns the XHR object (allows for cancelling the XHR if necessary).
 */
export function fetchSearchData({
    resource,
    uniqueFlvId,
    countOnly = false,
    loadMore = false,
    shouldLoadFromCache = true,
}) {
    return (dispatch, getState) => {
        const baseSearch = getState().search

        const { url, request } = buildRequestAndUrl(getState(), resource, uniqueFlvId)

        const { needSearchifyCountUpdate, isSearchifySubmittable } = baseSearch
            .getIn(["filterableListViews", uniqueFlvId, "searchify"], Map())
            .toJS()

        const currentFilters = selectCurrentFilters(getState(), { uniqueFlvId })
        const currentPage = baseSearch.getIn(["filterableListViews", uniqueFlvId, "currentPage"], List())

        if (countOnly) {
            request.filter({ count_only: true })
            dispatch({
                type: actionTypes.LOADING_COUNT_START,
                uniqueFlvId,
            })
        } else if (loadMore) {
            request.offset((currentPage + 1) * TASTYPIE_QUERY_LIMIT)
            dispatch({
                type: actionTypes.BEGIN_LOADING_MORE_DATA,
                uniqueFlvId,
            })
        } else {
            dispatch({
                type: actionTypes.LOADING_DATA_START,
                uniqueFlvId,
            })
            if (needSearchifyCountUpdate && isSearchifySubmittable) {
                request.filter({ need_searchify_count_update: true })
                dispatch({
                    type: searchifyActionTypes.LOADING_SEARCHIFY_COUNT_START,
                    uniqueFlvId,
                })
            }
        }

        const getRequest = request.GET({ shouldLoadFromCache })

        getRequest
            .then((response) => {
                if (countOnly) {
                    dispatch({
                        type: actionTypes.COUNT_LOADED,
                        count: response,
                        url,
                        uniqueFlvId,
                    })
                } else if (loadMore) {
                    dispatch({
                        type: actionTypes.LOADED_MORE_DATA,
                        data: response.objects,
                        url,
                        uniqueFlvId,
                    })
                } else {
                    if (needSearchifyCountUpdate && isSearchifySubmittable) {
                        dispatch(updateSearchifyCount(uniqueFlvId, response.searchify_count))
                    }
                    dispatch({
                        type: actionTypes.DATA_LOADED,
                        data: response.objects,
                        url,
                        uniqueFlvId,
                        currentFilters,
                    })
                }

                if (response.query_val_data && showQueryIssue) {
                    showQueryIssue(response.query_val_data)
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    // console.log('Request canceled at', new Date(), " for ", url)
                    // make sure to cancel the loading more request: unlike the count and
                    // data request, loading more won't call itself until the previous
                    // load more is cleared.
                    if (loadMore) {
                        dispatch({
                            type: actionTypes.CANCEL_LOADING_MORE_DATA,
                            uniqueFlvId,
                        })
                    }
                } else {
                    // If there is a backend error, notify the user of the error!
                    handleSearchError(error)

                    // clear the count in the case of an error.
                    if (countOnly) {
                        dispatch({
                            type: actionTypes.COUNT_LOADED,
                            count: 0,
                            url,
                            uniqueFlvId,
                        })
                    } else {
                        // store the error in the store.
                        dispatch({
                            type: actionTypes.LOADING_DATA_ERROR,
                            uniqueFlvId,
                            error,
                        })
                    }
                }
            })

        // return the request.
        return getRequest
    }
}

export function downloadSearchData({ resource, uniqueFlvId, actions }) {
    return (dispatch, getState) => {
        const { request } = buildRequestAndUrl(getState(), resource, uniqueFlvId)

        return request.filter(actions).download()
    }
}

export function triggerDataRefresh(uniqueFlvId, resource) {
    return (dispatch) => {
        dispatch(fetchSearchData({ resource, uniqueFlvId, shouldLoadFromCache: false }))
        dispatch(fetchSearchData({ resource, uniqueFlvId, countOnly: true, shouldLoadFromCache: false }))
    }
}

// logic to load default filters, getting them from UserInfo
export function loadDefaultSearchFilters() {
    return (dispatch) => {
        dispatch({
            type: actionTypes.LOADING_DEFAULT_SEARCH_FILTERS_START,
        })

        // create our full dehydrate dictionary
        const fullDehydrateDictionary = DjangIO.app.models.QuorumDataType.items()
            .filter((item) => item.advanced_search)
            .map((item) => item.key)
            .reduce((currentDict, nextItem) => {
                const media_key = DjangIO.app.models.QuorumDataType.media.key
                const media_article = DjangIO.app.models.QuorumDataType.media.related_field
                const nextQdtName = nextItem === media_key ? media_article : nextItem // Related field from key string.
                return {
                    ...currentDict,
                    [`full_dehydrate_${nextQdtName}_default_search_filters`]: true,
                }
            }, {})

        // get information
        return DjangIO.app.userdata.models.UserInfo.objects
            .get(Userdata.user_info_id)
            .filter(fullDehydrateDictionary)
            .GET()
            .then((response) => {
                // process the return object before sending to the store
                const processedDefaultSearchFilters = DjangIO.app.models.QuorumDataType.items()
                    .filter((item) => item.advanced_search)
                    .reduce((currentDict, qdtObj) => {
                        const qdtKey = qdtObj.key
                        const key = getQdtDefaultSearchFilters(qdtObj)
                        // access the savedSearch
                        const savedSearch = response[key] || {}
                        // get the filter array
                        const filterArr = savedSearch.filters || []
                        // if we have a filter, filter out model default filters
                        // and then put in dict!
                        let dict
                        if (filterArr.length) {
                            const modelDefaultFilters = DjangIO.modelAtPath(qdtObj.data_model).default_filters
                            // removing model default filters from the default filters
                            const foregroundFilter = Object.entries(filterArr[0]).reduce((acc, [filterKey, value]) => {
                                if (value !== modelDefaultFilters[filterKey]) {
                                    acc[filterKey] = value
                                }
                                return acc
                            }, {})

                            dict = { ...currentDict, [qdtKey]: foregroundFilter }
                        } else {
                            // otherwise, use empty dictionary
                            dict = { ...currentDict, [qdtKey]: {} }
                        }
                        return dict
                    }, {})

                // put them in the store
                dispatch({
                    type: actionTypes.LOADED_DEFAULT_SEARCH_FILTERS,
                    defaultSearchFilters: processedDefaultSearchFilters,
                })

                return fromJS(processedDefaultSearchFilters)
            })
    }
}
