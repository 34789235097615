import styled from "styled-components"

export const StyledWriteForm = styled.section`
    display: flex;
    flex-direction: column;
    gap: 24px;

    & .ai-disclaimer {
        margin-top: -12px;
    }
`
