import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { connect } from "react-redux"

import { MessageGroupLayout } from "QuorumGrassroots/campaign-forms/components/ReusableForms/MessageGroupLayout"
import SingleBodyMessageLayout from "QuorumGrassroots/campaign-forms/components/ReusableForms/SingleBodyMessageLayout"
import { withLock } from "QuorumGrassroots/styled-components/components/InputWithLock"
import { MultiSelect } from "QuorumGrassroots/styled-components/components/MultiSelect"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { StyledGroupSelector } from "QuorumGrassroots/styled-components/components/StyledGroupSelector"
import { StyledText } from "QuorumGrassroots/styled-components/components/StyledText"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"
import { StyledWriteForm } from "QuorumGrassroots/styled-components/components/StyledWriteForm"
import { QueriedMessageLayout } from "QuorumGrassroots/campaign-forms/components/ReusableForms/QueriedMessageLayout"
import { reinsertPlaceholders } from "QuorumGrassroots/helperFunctions"
import {
    generateTargetedMessage,
    validateMessageHasPlaceholders,
} from "QuorumGrassroots/framework/components/EditableMessagePreview/helper"
import { useDebouncedCallback } from "QuorumGrassroots/framework/hooks/useDebouncedCallback"
import { useFetchPlaceholdersFromMessage } from "QuorumGrassroots/services/grassrootsCampaign"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { updateFormattedTargetedMessage } from "QuorumGrassroots/campaign-forms/action-creators"
import { isWriteALetterCampaignWithOneClickRegistration } from "QuorumGrassroots/campaign-forms/helpers"
import { AIDisclaimer } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/AIDisclaimer"

export const MultiSelectWithLock = withLock(MultiSelect)

const { CampaignType, DraftOptions } = DjangIO.app.grassroots.campaign.types

const UnconnectedGenericWriteForm = ({ t, ...props }) => {
    const submitButtonRef = useRef(null)
    const [, setForceRerender] = useState(false)
    const [wrongPlaceholder, setWrongPlaceholder] = useState("")
    const [selectedGroupId, setSelectedGroupId] = useState(
        props.isQueryMode ? Object.keys(props.campaign.messages)[0] : props.activeGroupId,
    )

    const currentTargets = props.isQueryMode
        ? props.queriedTargets[props.campaign.campaign_type]?.[selectedGroupId]?.filter((target) => !target.is_custom)
        : props.multiSelectOptions?.filter((target) => !target.is_custom)

    const selectedTargets = useMemo(
        () => currentTargets?.filter((targets) => targets.targeted).map((target) => target.value),
        [currentTargets],
    )
    const firstTargetOfficialId = useMemo(() => selectedTargets?.[0], [selectedTargets])

    // we don't need the fake click workaround for the write a letter campaign because the one click is now
    // done straight from redux.
    // we will be able to remove this once the other one clicks are also migrated to work in this way
    const canSkipOneClickWorkaround = isWriteALetterCampaignWithOneClickRegistration(
        props.campaign,
        props.officialsPreview,
    )

    // We added this to re-render the component which fixes the issue of
    // one-click submit failing when social sharing is disabled
    useEffect(() => {
        if (canSkipOneClickWorkaround) return
        setForceRerender((prev) => !prev)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitButtonRef.current])

    const MultiSelectComponent = useMemo(
        () => (props.campaign.supporters_can_choose_targets ? MultiSelect : MultiSelectWithLock),
        [props.campaign],
    )

    const updatePlaceholdersMutation = useFetchPlaceholdersFromMessage(firstTargetOfficialId)
    const handleUpdatePlaceholders = useDebouncedCallback(async (rawMessage, content, officialId) => {
        if (!isFeatureEnabled("ff_ngg_message_preview")) return
        const currentMessagePreview = rawMessage
        const activeGroupId = parseInt(groupSelectorValue)
        const hasPlaceholders = validateMessageHasPlaceholders(content)
        if (!hasPlaceholders || !officialId) {
            props.updateFormattedTargetedMessage(activeGroupId, generateTargetedMessage(currentMessagePreview, content))
            return
        }
        const fixedPreContent = props.isQueryMode
            ? currentMessagePreview?.preBody
            : currentMessagePreview?.fixed_pre_message

        const fixedPostContent = props.isQueryMode
            ? currentMessagePreview?.postBody
            : currentMessagePreview?.fixed_post_message

        await updatePlaceholdersMutation
            .mutateAsync({
                content: reinsertPlaceholders(content),
                fixed_pre_content: reinsertPlaceholders(fixedPreContent || ""),
                fixed_post_content: reinsertPlaceholders(fixedPostContent || ""),
                person_id: officialId,
            })
            .then((response) => {
                if (props.isQueryMode) {
                    props.setQueriedMessages((lastMessages) => {
                        const group = lastMessages[props.campaign.campaign_type]?.[selectedGroupId]
                        const updatedGroup = {
                            ...group,
                            formatted: {
                                ...group.formatted,

                                content: response.content,
                                preBody: response?.fixed_pre_message ?? group.preBody,
                                postBody: response?.fixed_post_message ?? group.postBody,
                            },
                        }
                        return {
                            ...lastMessages,
                            [props.campaign.campaign_type]: {
                                ...lastMessages[props.campaign.campaign_type],
                                [selectedGroupId]: updatedGroup,
                            },
                        }
                    })
                } else {
                    props.updateFormattedTargetedMessage(activeGroupId, {
                        raw: { ...currentMessagePreview },
                        formatted: response,
                    })
                }
            })
    }, 500)

    const onChangeTargets = async (officialIds) => {
        if (props.isQueryMode) {
            const targets = props.queriedTargets[props.campaign.campaign_type][selectedGroupId]
            const updatedTargets = targets
                .map((target) => {
                    if (officialIds.includes(target.value)) {
                        return { ...target, targeted: true }
                    }
                    return { ...target, targeted: false }
                })
                .sort((a, b) => b.targeted - a.targeted) // targeted first, untargeted last

            props.setQueriedTargets((lastTargets) => {
                return {
                    ...lastTargets,
                    [props.campaign.campaign_type]: {
                        ...lastTargets[props.campaign.campaign_type],
                        [selectedGroupId]: updatedTargets,
                    },
                }
            })

            const firstOfficialHasChanged = targets[0]?.value !== updatedTargets[0]?.value
            if (!firstOfficialHasChanged) return

            const rawMessage = props.queriedMessages[props.campaign.campaign_type][selectedGroupId].raw
            const content = rawMessage?.content
            await handleUpdatePlaceholders(rawMessage, content, updatedTargets[0]?.value)
        }

        if (!props.isQueryMode && isFeatureEnabled("ff_ngg_message_preview")) {
            const isAdding = officialIds.length > selectedTargets.length
            // When adding a new official it is added in the end of the array.
            const placeholderCandidateId = isAdding ? officialIds[officialIds.length - 1] : officialIds[0]

            const placeholderCandidate = currentTargets.find((target) => target.value === placeholderCandidateId)
            const firstTarget = currentTargets.find((target) => target.value === firstTargetOfficialId)
            const hasFirstOfficialChanged = isAdding
                ? currentTargets.indexOf(placeholderCandidate) < currentTargets.indexOf(firstTarget)
                : placeholderCandidateId !== firstTargetOfficialId

            if (hasFirstOfficialChanged) {
                const rawMessage = props.targetedMessages?.[props.activeGroupId]?.raw
                const content = rawMessage?.content
                await handleUpdatePlaceholders(rawMessage, content, placeholderCandidateId)
            }
            props.toggleTarget(props.uniqueWidgetId, officialIds)
        }
    }

    const hasGroups = [CampaignType.write_member.value, CampaignType.personalized_messages.value].includes(
        props.campaign.campaign_type,
    )

    const isCombinedCampaign = props.campaign.campaign_type === CampaignType.combined_campaign.value
    const canEditCampaign = props.campaign.draft_requirements !== DraftOptions.cannot_edit.value

    const isWriteAMemberCampaign = props.campaign.campaign_type === CampaignType.write_member.value
    const shouldShowSelectorForMultiAction =
        isWriteAMemberCampaign && props.isQueryMode && Object.values(props.campaign.messages)?.length > 1
    const shouldShowGroupSelectorRedux = isWriteAMemberCampaign && props.messageGroups?.length > 1
    const shouldShowGroupSelector = shouldShowSelectorForMultiAction || shouldShowGroupSelectorRedux

    const shouldShowTargetSelectForMultiAction =
        props.isQueryMode &&
        hasGroups &&
        Object.values(props.campaign.messages)?.length > 0 &&
        currentTargets?.length > 0
    const shouldShowTargetSelectRedux = props.shouldShowMultiMessageTargetTable && currentTargets?.length > 0
    const shouldShowTargetSelect = shouldShowTargetSelectForMultiAction || shouldShowTargetSelectRedux

    const onChangeGroupSelector = (groupId) => {
        if (props.isQueryMode) {
            setSelectedGroupId(groupId)
            return
        }
        props.changeMessageGroup(props.uniqueWidgetId, groupId)
    }
    const groupSelectorValue = props.isQueryMode ? selectedGroupId : props.activeGroupId
    const groupSelectorData = props.isQueryMode
        ? Object.values(props.queriedMessages?.[props.campaign.campaign_type]).map((group) => ({
              value: String(group?.id),
              label: group?.name,
          }))
        : props.messageGroups.map((group) => ({ value: String(group.id), label: group.name }))

    const hasMessages = props.isQueryMode
        ? Object.keys(props.queriedMessages[props.campaign.campaign_type]).length
        : props.hasMessages

    if (!hasMessages) {
        return <div className="no-messages-text">{t("campaign.write.no_messages_to_send")}</div>
    }

    const submitDisabled =
        props.invalid || props.submitting || !Boolean(props.numberOfMessagesAfterFiltering) || wrongPlaceholder

    // If one click registration is enabled, emulate it by clicking the submit button for the advocate
    if (
        props.shouldSubmitOneClickRegistration &&
        !canSkipOneClickWorkaround &&
        !submitDisabled &&
        submitButtonRef.current
    ) {
        props.disableOneClickRegistration()
        submitButtonRef.current.props.onClick()
    }

    const getSubmitButtonProps = () => {
        const defaultProps = {
            onClick: props.handleSubmit,
            disabled: submitDisabled,
            "data-cy": "submit",
            isCampaignPage: true,
            activateNGGTheme: true,
            ref: submitButtonRef,
        }
        if (!props.isQueryMode) return defaultProps
        const selectedMessage = props.queriedMessages[props.campaign.campaign_type][selectedGroupId]
        const hasNoTargetsSelected = !selectedTargets?.length && !selectedMessage.is_custom
        return {
            ...defaultProps,
            disabled: props.isLoading || props.submitting || hasNoTargetsSelected,
            className: props.submitButtonClassName ?? "",
            onClick: () => props.customSubmit(props.campaign),
        }
    }

    const renderSubmitButton = () => {
        return (
            <StyledButton {...getSubmitButtonProps()}>
                {props.submitting ? t("form.submitting") : t(props.submitButtonTextKey)}
            </StyledButton>
        )
    }

    const renderMessages = () => {
        if (props.isQueryMode) {
            return (
                <QueriedMessageLayout
                    {...props}
                    t={t}
                    selectedGroupId={selectedGroupId}
                    firstSelectedTargetId={selectedTargets?.[0]}
                    wrongPlaceholder={wrongPlaceholder}
                    setWrongPlaceholder={setWrongPlaceholder}
                    isLoadingBody={updatePlaceholdersMutation.isLoading}
                />
            )
        }
        if (
            props.campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.combined_campaign.value
        ) {
            // if it's a combined campaign, we'll take care of the rendering in
            // CombineCampaignForm. Too much custom display logic to keep here.
            return null
        } else if (isWriteAMemberCampaign) {
            return (
                <MessageGroupLayout
                    {...props}
                    t={t}
                    firstOfficialSelectedId={firstTargetOfficialId}
                    isLoadingBody={updatePlaceholdersMutation.isLoading}
                    wrongPlaceholder={wrongPlaceholder}
                    setWrongPlaceholder={setWrongPlaceholder}
                />
            )
        } else {
            return <SingleBodyMessageLayout {...props} t={t} />
        }
    }

    const hasAnyMessageInMessageGroupsAllowedEdition =
        props.campaign._extra?.message_groups?.some(
            (a) => a.advocate_editing_permission !== DraftOptions.cannot_edit.value,
        ) ?? false

    return (
        <StyledWriteForm className="write-form">
            {canEditCampaign && isWriteAMemberCampaign && (
                <StyledText>
                    {hasAnyMessageInMessageGroupsAllowedEdition
                        ? t("campaign.write.edit_messages")
                        : t("campaign.write.cant_edit_messages")}
                </StyledText>
            )}
            {shouldShowGroupSelector && (
                <StyledGroupSelector
                    data-cy="group-selector"
                    value={groupSelectorValue}
                    onChange={onChangeGroupSelector}
                    data={groupSelectorData}
                />
            )}
            {shouldShowTargetSelect && (
                <MultiSelectComponent
                    clearable
                    onChange={onChangeTargets}
                    value={selectedTargets}
                    data={currentTargets}
                    label={`Officials (${selectedTargets?.length})`}
                />
            )}
            {isCombinedCampaign && renderSubmitButton()}
            {renderMessages()}
            {props.children}
            {wrongPlaceholder && (
                <StyledContrastText isCampaignPage isSingleColumnLayout={props.isSingleColumnLayout}>
                    Invalid text, remove {wrongPlaceholder} in order to save
                </StyledContrastText>
            )}
            {props.showAIDisclaimer && <AIDisclaimer className="ai-disclaimer" />}
            {renderSubmitButton()}
        </StyledWriteForm>
    )
}

UnconnectedGenericWriteForm.propTypes = {
    // from campaign container
    disableOneClickRegistration: PropTypes.func.isRequired,
    // from campaignPassThroughProps in campaign/selectors
    shouldSubmitOneClickRegistration: PropTypes.bool.isRequired,
    // from write-selectors
    shouldShowMultiMessageTargetTable: PropTypes.bool,
    submitButtonTextKey: PropTypes.string,
    // from general-selectors
    hasMessages: PropTypes.bool,
    numberOfMessagesAfterFiltering: PropTypes.number,
    activeSelectId: PropTypes.string,
    campaign: PropTypes.object,
    remainingMessageIds: PropTypes.arrayOf(PropTypes.string),
    // provided by redux form
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,

    showAIDisclaimer: PropTypes.bool,
}

const mapStateToProps = (state) => {
    return {
        targetedMessages: state.widgets.toJS().targetedMessages,
        officialsPreview: state.widgets.toJS()?.officialsPreview,
    }
}

export const GenericWriteForm = connect(mapStateToProps, { updateFormattedTargetedMessage })(
    UnconnectedGenericWriteForm,
)
