import React, { useState } from "react"
import { Collapsable, Container, Content, RotateIcon, ToggleButton } from "./AIDisclaimer.styles"

export const AIDisclaimer = (props: { className?: string }) => {
    const [isOpened, setIsOpened] = useState(false)

    const text = isOpened ? "Hide AI Disclaimer" : "AI Disclaimer"

    return (
        <Container className={props.className}>
            <div>
                <i className="fa fa-magic" aria-hidden="true"></i>
            </div>
            <Content>
                <Collapsable isOpened={isOpened}>
                    <p>
                        We used AI to adjust the organization’s message to work with your responses. Your responses were
                        not affected by the AI. We suggest you review your message before sending to ensure it’s
                        quality.
                    </p>
                </Collapsable>
                <ToggleButton type="button" onClick={() => setIsOpened(!isOpened)} isOpened={isOpened}>
                    <p>{text}</p>
                    <RotateIcon shouldRotate={isOpened}>
                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </RotateIcon>
                </ToggleButton>
            </Content>
        </Container>
    )
}
